<template>
  <div>
    <!-- 
      <b-alert v-if="isProfile" variant="primary" show>
      <div class="alert-body">
        <span>
          <strong>complete your account ! </strong>
          please complete your profile to access our system features .
          <strong>
            <b-link :to="{ name: 'pages-account-setting' }"> Profile </b-link>
          </strong>
        </span>
      </div>
    </b-alert>
    <b-alert v-if="isDocs" variant="primary" show>
      <div class="alert-body">
        <span>
          <strong>complete your account ! </strong>
          please upload your documents to verify your account .
          <strong>
            <b-link :to="{ name: 'pages-account-setting' }"> Profile </b-link>
          </strong>
        </span>
      </div>
    </b-alert> -->
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BAlert } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BAlert,
  },
  data() {
    return {
      username: "",
      isProfile: false,
      isDocs: false,
    };
  },
  mounted() {
    this.getUsername();
  },
  methods: {
    getUsername() {
      const that = this;


      Backendless.UserService.getCurrentUser()
        .then((user) => {

          this.currentUser = user;
          if (user.FirstName?.length && user.LastName?.length) {
            this.isProfile = false;
          } else {
            this.isProfile = true;
            that.$toast({
              component: ToastificationContent,
              props: {
                title: "Please complete your profile",
                icon: "BellIcon",

                // text: this.username,
                variant: "danger",
              },
            });
          }

          if (user.enabled) {
            this.isDocs = false;
          } else {
            this.isDocs = true;
            that.$toast({
              component: ToastificationContent,
              props: {
                title: "Please upload your documents",
                icon: "BellIcon",

                // text: this.username,
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {

          console.error(`can't get user ${error}`);
        });
    },
  },
};
</script>

<style></style>
