export default [
  {
    title: 'Analytics',
    route: 'home',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
  }, {
    title: 'Research Studies',
    route: 'gameresearches',
    icon: 'StarIcon',
    tagVariant: 'light-warning',
  },
  {
    title: 'Categories',
    route: 'categories',
    icon: 'ShieldIcon',
    tagVariant: 'light-warning',
  },
]
